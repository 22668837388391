<template>
  <v-container fluid class='pt-0 overflow-y-auto'>

    <v-row class=''>
      <v-col>
        <v-btn class='primary' @click='createProduct'><v-icon>mdi-plus</v-icon>New Product</v-btn>
      </v-col>
    </v-row>

    <v-row ref='sortArea' class='px-2'>

      <v-col cols=12 lg=6 xl=4 v-for="prod in products" v-bind:key="prod.xid" class='pa-1' :data-id="prod.xid">
        <v-card class='product py-2' :disabled='loading' @click='clickEdit(prod)'>
          <div class='dragMe cardbtn'><v-icon>mdi-drag</v-icon></div>
          <div class='duplicate cardbtn' @click.stop="duplicate(prod)"><v-icon small>mdi-content-copy</v-icon></div>
          <div class='delete cardbtn' @click.stop="Delete(prod)"><v-icon small>mdi-trash-can-outline</v-icon></div>
          <span class='text-overline cardbtn tax' v-if='prod.tax.length'>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}"><span v-on="on" v-bind="attrs">TAX</span></template>
              <span v-if='taxes_loading === false'>
                <span v-for="tax in prod.tax" v-bind:key='tax.xid'>
                  {{taxes.find(t=>t.xid==tax).title}}<br/>
                </span>
              </span>
            </v-tooltip>
          </span>

          <v-list-item class='pl-8 pr-14'>
              <template v-if="prod.tier"><TierIcon :tier="prod.tier" size="50px" class='mr-4' /></template>
            <v-list-item-content class='py-1'>
              <v-list-item-title class='text-body-1 py-1 font-weight-medium' v-text='prod.title' />
              <v-list-item-subtitle>
                <span v-if='prod.description'>{{prod.description}}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

    </v-row>


    <v-dialog v-model="editProduct.show" v-if='editProduct.show' max-width="500" persistent>
      <v-card elevation="20" :disabled='editProduct.loading'>
        <v-card-title class="text-h6 primary white--text py-3">
          Update Product
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2'>
          <v-row style='py-0'>
            <v-col cols=12 class='py-0'><v-text-field v-model='editProduct.title' outlined dense hide-details label='Title' /></v-col>
            <v-col cols=12 class='py-0'>
              <v-autocomplete v-model='editProduct.tier' :loading='tiers_loading' :items='tiers' item-text="code" :item-value="null" :menu-props="{overflowX: false}" >
                <template v-slot:item="data">
                  <TierIcon :tier="data.item" size="50px" class='my-1 mr-4' />
                  <v-list-item-content class='py-1'>
                    <span class='text-h6 py-0 my-0'>${{((data.item.price || 0)/100).toFixed(2)}}</span>
                    <p class='text-caption my-0' style='width:250px;'>{{data.item.description}}</p>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <TierIcon :tier="data.item" size="50px" class='mb-2 mr-4' />
                  <v-list-item-content>
                    <v-list-item-title>${{((data.item.price || 0)/100).toFixed(2)}}</v-list-item-title>
                    <v-list-item-subtitle style='overflow:hidden;'>{{data.item.description}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols=12 class='py-0'><v-textarea :value='editProduct.description' @change='editProduct.description = $event' outlined dense label='Description' counter="100" maxLength="100" /></v-col>
            <v-col cols=12 class='py-0'>
              <v-autocomplete v-model='editProduct.taxes' :loading='taxes_loading' :items='taxes' item-text='title' item-value='xid' multiple chips label='Tax'>
                <template v-slot:item="data">
                  <v-list-item-content class='py-2'>
                    <span class='py-0 my-0'>
                      <span class='font-weight-medium'>{{data.item.title}}</span>
                      <span> ({{parseFloat(data.item.rate)}}%)</span>
                    </span>
                    <p class='text-caption my-0' style='width:250px;'>{{data.item.description}} </p>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class='pb-4'>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='editProduct.show = false;'>Cancel</v-btn>
          <v-btn color="primary darken-1" @click='updateProduct'>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<style lang="scss">
@import "@/assets/common";
.product{
  user-select: none;
  .cardbtn{
    position:absolute;
    z-index:1;
    top: 6px;
    &.dragMe{
      cursor: move;
      left:4px;
    }
    &.duplicate{
      cursor: pointer;
      right:30px;
      &:hover .v-icon{ color: $primary; }
    }
    &.delete{
      cursor: pointer;
      right:8px;
      &:hover .v-icon{ color: $primary; }
    }
    &.tax{
      top: auto;
      bottom: 4px;
      right: 10px;
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex';
import Sortable from 'sortablejs';
import TierIcon from '@/components/Billing/TierIcon'
export default {
  name: 'Products',

  components: {
    TierIcon,
  },

  data: () => ({
    loading: false,
    products: [],
    sortable: null,
    editProduct: {
      show: false,
      loading: false,
      title: null,
      description: "wow",
      taxes: null,
    },
    tiers: [],
    tiers_loading: null, //true when loading, false when finished loading
    taxes: [],
    taxes_loading: null, //true when loading, false when finished loading
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['PROMPT', 'CONFIRM']),

    refreshProducts(){
        this.loading = true;
        this.API({ method: 'GET', endpoint: 'products' })
        .then(products => {
          this.products.splice(0);
          this.$nextTick(() => { this.products = products; });
          this.products = products;
        })
        .finally(() => { this.loading = false; });
    },

    createProduct(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'products' })
      .then(product => {
        console.log(product);
        this.refreshProducts();
        this.clickEdit(product);
      })
      .catch(() => { this.loading = false; });
    },

    loadTiers(){
      this.tiers_loading = true;
        this.API({ method: 'GET', endpoint: 'tiers' })
        .then(tiers => { this.tiers = tiers; })
        .finally(() => { this.tiers_loading = false; });
    },

    loadTaxes(){
      this.taxes_loading = true;
      this.API({ method: 'GET', endpoint: 'taxes' })
      .then(tiers => { this.taxes = tiers; })
      .finally(() => { this.taxes_loading = false; });
    },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortArea, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.updateOrder,
      });
    },

    updateOrder(e){
      if(e.oldIndex == e.newIndex) return;
      let sort_order = this.sortable.toArray();
      this.API({ method: 'POST', endpoint: `products/sort_order`, data: { sort_order }, autosave: 3000, })
    },

    duplicate(p){
      this.loading = true;
      this.$nextTick(() => {
        this.API({ method: 'POST', endpoint: `products/${p.xid}/duplicate` })
        .then(this.refreshProducts);
      });

    },

    Delete(p){
      this.CONFIRM({ title: "Delete?", message: `Delete product ${p.title}?`})
      .then(() => {
        this.API({ method: 'DELETE', endpoint: `products/${p.xid}` })
        .then(this.refreshProducts);
      });
    },

    clickEdit(p){
      if(this.tiers_loading === null) this.loadTiers();
      this.editProduct.xid = p.xid;
      this.editProduct.title = p.title;
      this.editProduct.description = p.description;
      this.editProduct.tier = p.tier;
      this.editProduct.taxes = p.tax;
      this.editProduct.show = true;
    },

    updateProduct(){
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `products/${this.editProduct.xid}`, data: {
        title: this.editProduct.title,
        description: this.editProduct.description,
        tier: this.editProduct.tier?.xid || "",
        taxes: this.editProduct.taxes || [],
      }})
      .then(newData => {
        let i = this.products.findIndex(p => p.xid == newData.xid);
        this.products[i] = newData;
        this.editProduct.show = false;
      }).
      finally(() => { this.loading = false; });
    }

  },

  mounted() {
    this.makeSortable();
    this.refreshProducts();
    this.loadTaxes();
  },

};
</script>
