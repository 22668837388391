var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {}, [_c('v-col', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.createProduct
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New Product")], 1)], 1)], 1), _c('v-row', {
    ref: "sortArea",
    staticClass: "px-2"
  }, _vm._l(_vm.products, function (prod) {
    return _c('v-col', {
      key: prod.xid,
      staticClass: "pa-1",
      attrs: {
        "cols": "12",
        "lg": "6",
        "xl": "4",
        "data-id": prod.xid
      }
    }, [_c('v-card', {
      staticClass: "product py-2",
      attrs: {
        "disabled": _vm.loading
      },
      on: {
        "click": function click($event) {
          return _vm.clickEdit(prod);
        }
      }
    }, [_c('div', {
      staticClass: "dragMe cardbtn"
    }, [_c('v-icon', [_vm._v("mdi-drag")])], 1), _c('div', {
      staticClass: "duplicate cardbtn",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.duplicate(prod);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-content-copy")])], 1), _c('div', {
      staticClass: "delete cardbtn",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.Delete(prod);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-trash-can-outline")])], 1), prod.tax.length ? _c('span', {
      staticClass: "text-overline cardbtn tax"
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v("TAX")])];
        }
      }], null, true)
    }, [_vm.taxes_loading === false ? _c('span', _vm._l(prod.tax, function (tax) {
      return _c('span', {
        key: tax.xid
      }, [_vm._v(" " + _vm._s(_vm.taxes.find(function (t) {
        return t.xid == tax;
      }).title)), _c('br')]);
    }), 0) : _vm._e()])], 1) : _vm._e(), _c('v-list-item', {
      staticClass: "pl-8 pr-14"
    }, [prod.tier ? [_c('TierIcon', {
      staticClass: "mr-4",
      attrs: {
        "tier": prod.tier,
        "size": "50px"
      }
    })] : _vm._e(), _c('v-list-item-content', {
      staticClass: "py-1"
    }, [_c('v-list-item-title', {
      staticClass: "text-body-1 py-1 font-weight-medium",
      domProps: {
        "textContent": _vm._s(prod.title)
      }
    }), _c('v-list-item-subtitle', [prod.description ? _c('span', [_vm._v(_vm._s(prod.description))]) : _vm._e()])], 1)], 2)], 1)], 1);
  }), 1), _vm.editProduct.show ? _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "persistent": ""
    },
    model: {
      value: _vm.editProduct.show,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "show", $$v);
      },
      expression: "editProduct.show"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editProduct.loading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Update Product ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Title"
    },
    model: {
      value: _vm.editProduct.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "title", $$v);
      },
      expression: "editProduct.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.tiers_loading,
      "items": _vm.tiers,
      "item-text": "code",
      "item-value": null,
      "menu-props": {
        overflowX: false
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('TierIcon', {
          staticClass: "my-1 mr-4",
          attrs: {
            "tier": data.item,
            "size": "50px"
          }
        }), _c('v-list-item-content', {
          staticClass: "py-1"
        }, [_c('span', {
          staticClass: "text-h6 py-0 my-0"
        }, [_vm._v("$" + _vm._s(((data.item.price || 0) / 100).toFixed(2)))]), _c('p', {
          staticClass: "text-caption my-0",
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(_vm._s(data.item.description))])])];
      }
    }, {
      key: "selection",
      fn: function fn(data) {
        return [_c('TierIcon', {
          staticClass: "mb-2 mr-4",
          attrs: {
            "tier": data.item,
            "size": "50px"
          }
        }), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("$" + _vm._s(((data.item.price || 0) / 100).toFixed(2)))]), _c('v-list-item-subtitle', {
          staticStyle: {
            "overflow": "hidden"
          }
        }, [_vm._v(_vm._s(data.item.description))])], 1)];
      }
    }], null, false, 178587583),
    model: {
      value: _vm.editProduct.tier,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "tier", $$v);
      },
      expression: "editProduct.tier"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": _vm.editProduct.description,
      "outlined": "",
      "dense": "",
      "label": "Description",
      "counter": "100",
      "maxLength": "100"
    },
    on: {
      "change": function change($event) {
        _vm.editProduct.description = $event;
      }
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.taxes_loading,
      "items": _vm.taxes,
      "item-text": "title",
      "item-value": "xid",
      "multiple": "",
      "chips": "",
      "label": "Tax"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', {
          staticClass: "py-2"
        }, [_c('span', {
          staticClass: "py-0 my-0"
        }, [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(data.item.title))]), _c('span', [_vm._v(" (" + _vm._s(parseFloat(data.item.rate)) + "%)")])]), _c('p', {
          staticClass: "text-caption my-0",
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(_vm._s(data.item.description) + " ")])])];
      }
    }], null, false, 3468491411),
    model: {
      value: _vm.editProduct.taxes,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "taxes", $$v);
      },
      expression: "editProduct.taxes"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.editProduct.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.updateProduct
    }
  }, [_vm._v("Update")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }